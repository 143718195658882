import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ContentWrapper } from "./ContentWrapper";

export const MainLayout = () => {
  return (
    <ContentWrapper>
      <Grid
        container
        justifyContent="center"
        alignItems={"center"}
        sx={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Outlet />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};
