// This is the absolute base url of the backend API. It is set with the
// environment variable REACT_APP_API_BASE_URL.
export const ApiBaseUrl = () => {
  if (process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  }

  return "https://krypticapi.hyperspheretech.com";
};

// This is the absolute base url of the parent window i.e. the calling
// web app application window URL. It is set with the environment variable
// REACT_APP_PARENT_WINDOW_ORIGIN_URL.
export const ParentWindowOrigin = () => {
  /*
  if (process.env.REACT_APP_PARENT_WINDOW_ORIGIN_URL) {
    return process.env.REACT_APP_PARENT_WINDOW_ORIGIN_URL;
  }
    */

  //return "https://cdcontainer.hyperspheretech.com";
  return "*";
};

export const WithApiBaseUrl = (path: string) => {
  return ApiBaseUrl() + path;
};
