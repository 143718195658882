import { Navigate, useLocation } from "react-router-dom";

export const ControlledRoute = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { children } = props;

  let workflowStagePath = localStorage.getItem("workflowStagePath");
  if (workflowStagePath === null) {
    workflowStagePath = "/";
    localStorage.setItem("workflowStagePath", workflowStagePath);
  }

  const location = useLocation();
  if (location.pathname === workflowStagePath) {
    return <>{children}</>;
  }

  return <Navigate to={workflowStagePath} />;
};
