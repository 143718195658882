import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Main from "../Layout/Main";
import { ChangeEvent, FormEvent, useState } from "react";
import axios from "axios";
import { WithApiBaseUrl } from "../Util/Environment";
import { ContactSupport } from "../Util/ErrorMessages";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

const emailRegex =
  /(?!^[.+&'_-]*@.*$)(^[_\w\d+&'-]+(\.[_\w\d+&'-]*)*@[\w\d-]+(\.[\w\d-]+)*\.(([\d]{1,3})|([\w]{2,}))$)/;

export const RegisterEmail = () => {
  const navigate = useControlledNavigate();

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (!email) {
      setEmailError("Please enter an email address");
      setTouched(true);
      return;
    }

    if (emailError) {
      return;
    }

    axios
      .post(WithApiBaseUrl("/api/v1/user/email/register"), {
        email,
        entry_id: localStorage.getItem("entry_id"),
      })
      .then(() => {
        navigate("/confirm-email");
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setError("Please enter a valid email address");
        } else {
          console.log(error);
          setError("Failed to register email. " + ContactSupport);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    setEmail(event.target.value);

    if (!emailRegex.test(event.target.value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }

  return (
    <Main title="Register Email Address">
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
              Provide the email address you'd like for us to deliver your seed
              phrased protected via a Protected Seed Phrase. You will need to
              provide this Protected Seed Phrase in order to recover your seed
              phrase.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
              <span style={{ fontStyle: "bold" }}>Recommended:</span> Consider
              creating a separate dedicated email account using a privacy
              respecting provider exclusively for the purpose of storing your
              Protected Seed Phrase (ex: Proton Mail)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-email"
              sx={{
                color: '#bd81d6',
                '&.Mui-focused': {
                  color: '#bd81d6',
                },
              }}
              >Email</InputLabel>
              <OutlinedInput
                onChange={handleInputChange}
                autoFocus
                onBlur={() => {
                  setTouched(true);
                }}
                value={email}
                disabled={submitting}
                id="outlined-adornment-email"
                fullWidth
                label="Email"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  input: {
                    color: 'white',
                  },
                }}
              />
              {touched && <FormHelperText error>{emailError}</FormHelperText>}
            </FormControl>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography
                textAlign={"center"}
                variant="subtitle2"
                color="error"
              >
                {error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {!submitting && (
              <Button
                variant="contained"
                fullWidth
                type="submit"
                disableElevation
                style={{
                  borderRadius: 8,
                  backgroundColor: "#bd81d6",
                  fontSize: "18px",
                  color: "#ffffff"
                }}
              >
                Submit
              </Button>
            )}
            {submitting && <CircularProgress />}
          </Grid>
        </Grid>
      </form>
    </Main>
  );
};
