import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Main from "../Layout/Main";
import { ChangeEvent, FormEvent, useState } from "react";
import axios from "axios";
import { ParentWindowOrigin, WithApiBaseUrl } from "../Util/Environment";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";
import { ContactSupport } from "../Util/ErrorMessages";

type RecoveryResponse = {
  seed: string;
};
export const RecoveryPrompt = () => {
  // Starting from scratch - recovery workflow. Clear localStorage in case
  // we didn't complete a previous one
  localStorage.clear();

  const navigate = useControlledNavigate();

  const [totpInputError, setTotpInputError] = useState("");
  const [totpInputTouched, setTotpInputTouched] = useState(false);
  const [totpInput, setTotpInput] = useState("");

  const [file, setFile] = useState<File>();
  const [fileTouched, setFileTouched] = useState(false);
  const [fileError, setFileError] = useState("");

  const [responseError, setResponseError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (totpInputError || fileError) {
      return;
    }

    setSubmitting(true);
    file
      ?.text()
      .then((text) => {
        axios
          .post<RecoveryResponse>(WithApiBaseUrl("/api/v1/user/seed/recover"), {
            totp_token: totpInput,
            base64_encrypted_payload: text,
          })
          .then((res) => {
            window.top?.postMessage(
              {
                request: "recover-seed",
                payload: res.data.seed,
              },
              ParentWindowOrigin(),
            );
            navigate("/recovery-confirm");
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 400) {
              setResponseError("Invalid TOTP or file");
            } else if (error.response.status === 404) {
              setResponseError("Seed not found. " + ContactSupport);
            } else {
              setResponseError("Failed to recover seed. " + ContactSupport);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch((error) => {
        setFileError("Failed to read file. " + error);
        console.log(error);
      });
  }

  function handleTotpChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setTotpInput(event.target.value);
    if (!event.target.value) {
      setTotpInputError("Please enter a TOTP");
    } else {
      setTotpInputError("");
    }
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files || !event.target.files[0]) {
      setFileError("Please provide a file");
    } else {
      setFile(event.target.files[0]);
      setFileError("");
    }
  }

  return (
    <Main title="Recovery Prompt">
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" textAlign={"center"} color="#ffffff">
              Provide us a copy of your Protected Seed Phrase that was
              previously emailed to you along with a valid TOTP token via your
              Authenticator App.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                disabled={submitting}
                onChange={handleFileChange}
                onBlur={() => setFileTouched(true)}
                type="file"
                id="encrypted-payload"
                fullWidth
                InputProps={{
                  sx: {
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#bd81d6',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#bd81d6',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#bd81d6',
                    },
                  },
                }}
                inputProps={{
                  style: {
                    color: 'white',
                  },
                }}
              />
              {fileTouched && (
                <FormHelperText error id="encrypted-payload">
                  {fileError}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="totp-token"
              sx={{
                color: '#bd81d6',
                '&.Mui-focused': {
                  color: '#bd81d6',
                },
              }}
              >TOTP Token</InputLabel>
              <OutlinedInput
                value={totpInput}
                onChange={handleTotpChange}
                onBlur={() => setTotpInputTouched(true)}
                autoComplete="off"
                autoFocus
                id="totp-token"
                disabled={submitting}
                label="TOTP Token"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  input: {
                    color: 'white',
                  },
                }}
              />
              {totpInputTouched && (
                <FormHelperText error id="totp-token">
                  {totpInputError}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography color="error" variant="subtitle2" textAlign={"center"}>
              {responseError}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={submitting}
              fullWidth
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Main>
  );
};
