import { Button, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Main from "../Layout/Main";
import axios from "axios";
import { ParentWindowOrigin, WithApiBaseUrl } from "../Util/Environment";
import { useEffect, useState } from "react";
import { ContactSupport } from "../Util/ErrorMessages";

export const ProtectSeedConfirm = () => {
  const [searchParams] = useSearchParams();

  const deliveryMechanism = searchParams.get("delivery_mechanism");
  const [error, setError] = useState("");

  function handleDone() {
    const entryID = localStorage.getItem("entry_id");
    axios({
      method: "delete",
      url: WithApiBaseUrl("/api/v1/user/payload"),
      data: {
        entry_id: entryID,
      },
    })
      .then(() => {
        localStorage.clear();
        window.top?.postMessage(
          {
            request: "protect-seed-complete",
            payload: "",
          },
          ParentWindowOrigin(),
        );
      })
      .catch(console.error);
  }

  function handleSendEmail(): void {
    const entryID = localStorage.getItem("entry_id");
    axios({
      method: "post",
      url: WithApiBaseUrl("/api/v1/user/payload/email"),
      data: {
        entry_id: entryID,
      },
    })
      .then(() => {
        setError("");
      })
      .catch((error) => {
        setError("Failed to send email. " + ContactSupport);
        console.log(error);
      });
  }

  function handleDownload(): void {
    const entryID = localStorage.getItem("entry_id");
    axios({
      method: "post",
      url: WithApiBaseUrl("/api/v1/user/payload/download"),
      data: {
        entry_id: entryID,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payload.bin");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setError("Failed to download payload. " + ContactSupport);
        console.log(error);
      });
  }

  useEffect(() => {
    if (deliveryMechanism === "email") {
      handleSendEmail();
    } else if (deliveryMechanism === "download") {
      handleDownload();
    } else {
      setError("Invalid delivery mechanism");
    }
  }, [deliveryMechanism]);

  return (
    <Main title="Confirmation">
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="subtitle2" textAlign={"center"} color="#ffffff">
            Congrats! Your seed phrase has been protected via HyperSphere.
            {deliveryMechanism === "email" && (
              <span>
                &nbsp;A Protected Seed Phrase was delivered to your email
                address. Please verify that you received the email.
              </span>
            )}
            {deliveryMechanism === "download" && (
              <span>
                &nbsp;Please verify that you successfully downloaded the
                Protected Seed Phrase to your device.
              </span>
            )}
            &nbsp;You will need to provide this Protected Seed Phrase along with
            a correct TOTP token to recover your seed phrase.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontWeight={"bold"}
            variant="subtitle2"
            textAlign={"center"}
            color="#ffffff"
          >
            We cannot recover your seed phrase if you lose the Protected Seed
            Phrase or you cannot provide a correct TOTP token
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" variant="subtitle2" textAlign={"center"}>
            {error}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {deliveryMechanism === "email" && (
            <Button
              variant="contained"
              onClick={handleSendEmail}
              fullWidth
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
              }}
            >
              Resend Email
            </Button>
          )}
          {deliveryMechanism === "download" && (
            <Button
              variant="contained"
              onClick={handleDownload}
              fullWidth
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
              }}
            >
              Re-Download
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleDone}
            variant="outlined"
            fullWidth
            disableElevation
            style={{
              borderRadius: 8,
              backgroundColor: "#bd81d6",
              fontSize: "18px",
              color: "#ffffff"
            }}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    </Main>
  );
};
