import { Box, Button, Grid, Typography } from "@mui/material";
import Main from "../Layout/Main";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

export const Welcome = () => {
  // Starting from scratch. Clear localStorage
  localStorage.clear();

  const navigate = useControlledNavigate();
  const handleClick = () => {
    navigate("/instructions");
  };

  return (
    <Main title="">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
            This will guide you through the process of protecting your seed phrase
            via the creation of a{" "}
            <span style={{ fontStyle: "italic" }}>
              Protected Seed Phrase file
            </span>
            ; a cryptographic representation of your seedphase resistant to
            post-quantum threats.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
            You need only provide us a copy of your Protected Seed Phrase file
            anytime you wish to recover your protected seed phrase.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
            Click Next to proceed.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" px={"25%"}>
            <Button
              onClick={handleClick}
              variant="contained"
              fullWidth
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
            }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Main>
  );
};
