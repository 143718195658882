import { Path, To, useNavigate } from "react-router-dom";

export interface ControlledNavigateFunction {
  (to: To): void;
}
export const useControlledNavigate = (): ControlledNavigateFunction => {
  const navigate = useNavigate();
  return (to: To) => {
    let path: string;
    if (typeof to === "string") {
      path = to;
    } else {
      path = (to as Path).pathname;
    }

    localStorage.setItem("workflowStagePath", path);
    navigate(to);
  };
};
