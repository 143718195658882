import { Button, Grid, Typography } from "@mui/material";
import Main from "../Layout/Main";
import { ParentWindowOrigin } from "../Util/Environment";

export const RecoveryConfirm = () => {
  function doneHandler() {
    window.top?.postMessage(
      {
        request: "recover-seed-complete",
        payload: "",
      },
      ParentWindowOrigin(),
    );
  }

  return (
    <Main title="Recovery Prompt">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" textAlign={"center"} color="#ffffff">
            Recovery Successful!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" textAlign={"center"} color="#ffffff">
            Your protected seed phrase is now accessible to you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={doneHandler}
            variant="contained"
            fullWidth
            disableElevation
            style={{
              borderRadius: 8,
              backgroundColor: "#bd81d6",
              fontSize: "18px",
              color: "#ffffff"
            }}
          >
            Done
          </Button>
        </Grid>
      </Grid>
    </Main>
  );
};
