import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Main from "../Layout/Main";
import axios from "axios";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { WithApiBaseUrl } from "../Util/Environment";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

type GeneratedTOTP = {
  image_base64: string;
  entry_id: string;
};

const totpCodeRegex = /^[0-9]{6}$/;

export const RegisterTOTP = () => {
  const navigate = useControlledNavigate();

  const [totpImage, setTotpImage] = useState("");
  const [error, setError] = useState<string>();

  const [touched, setTouched] = useState(false);
  const [totpInput, setTotpInput] = useState("");
  const [totpInputError, setTotpInputError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    if (totpCodeRegex.test(event.target.value)) {
      setTotpInputError("");
    } else {
      setTotpInputError("One time code must be 6 digits long");
    }

    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 6) {
      setTotpInput(onlyNums);
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (!totpInput) {
      setTotpInputError("Please enter a TOTP");
      return;
    }

    if (totpInputError) {
      return;
    }

    setSubmitting(true);
    axios
      .post(WithApiBaseUrl("/api/v1/user/totp/verify"), {
        passcode: totpInput,
        entry_id: localStorage.getItem("entry_id"),
      })
      .then(() => {
        navigate("/delivery-mechanism");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError("Invalid TOTP");
        } else {
          console.log(error);
          setError("Failed to verify TOTP");
        }
      })
      .finally(() => {
        setSubmitting(false);
        setTotpInput("");
      });
  }

  useEffect(() => {
    axios
      .get<GeneratedTOTP>(WithApiBaseUrl("/api/v1/user/totp/generate"))
      .then((response) => {
        setTotpImage(response.data.image_base64);
        localStorage.setItem("entry_id", response.data.entry_id);
      })
      .catch((error) => {
        console.log(error);
        setError("Failed to generate TOTP");
      });
  }, []);
  return (
    <Main title="Register QR Code">
      <form noValidate onSubmit={handleSubmit}>
        <Grid
          container
          justifyContent={"center"}
          justifyItems={"center"}
          alignItems={"center"}
          spacing={2}
          direction={"column"}
        >
          <Grid item>
            {!totpImage && <CircularProgress />}
            {totpImage && (
              <Box border={2} borderRadius={"10px"} borderColor="#bd81d6" p={1}>
                <img alt="QR Code" src={totpImage} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="subtitle2"  color="#ffffff">
              Scan the above QR Code using your preferred Authenticator App (ex:
              Microsoft Authenticator). Then enter the TOTP code below and click
              Submit
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-totp"
                sx={{
                  color: '#bd81d6',
                  '&.Mui-focused': {
                    color: '#bd81d6',
                  },
                }}
              >TOTP</InputLabel>
              <OutlinedInput
                onChange={handleInputChange}
                autoFocus
                onBlur={() => {
                  setTouched(true);
                }}
                disabled={submitting}
                autoComplete="off"
                value={totpInput}
                id="outlined-adornment-totp"
                label="TOTP"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  input: {
                    color: 'white',
                  },
                }}
              />
              {touched && (
                <FormHelperText error>{totpInputError}</FormHelperText>
              )}
            </FormControl>
            <Grid item xs={12} textAlign={"center"} mt={2}>
              {error && <Typography color={"error"}>{error}</Typography>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!submitting && (
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={submitting}
                style={{
                  borderRadius: 8,
                  backgroundColor: "#bd81d6",
                  fontSize: "18px",
                  color: "#ffffff"
                }}
              >
                Submit
              </Button>
            )}
            {submitting && <CircularProgress />}
          </Grid>
        </Grid>
      </form>
    </Main>
  );
};
