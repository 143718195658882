import { CircularProgress, Grid, Typography } from "@mui/material";
import Main from "../Layout/Main";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ParentWindowOrigin, WithApiBaseUrl } from "../Util/Environment";
import axios from "axios";
import { ContactSupport } from "../Util/ErrorMessages";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

enum Status {
  WaitingForSeed = "waiting",
  ProtectingSeed = "protecting",
  Success = "success",
  Error = "error",
}

export const ProtectSeed = () => {
  const [params] = useSearchParams();
  const navigate = useControlledNavigate();
  const [seed, setSeed] = useState<string>();
  const [status, setStatus] = useState<Status>(Status.WaitingForSeed);

  const messageListener = (event: MessageEvent) => {
    // from webview we don't know what the parentorigin will be
    /*
    if (event.origin !== ParentWindowOrigin()) {
      return;
    }
      */

    if (event.data.request !== "protect-seed") {
      return;
    }

    console.log(event.data.payload);
    setSeed(event.data.payload);
  };

  useEffect(() => {
    window.addEventListener("message", messageListener);

    window.top?.postMessage(
      {
        request: "send-seed",
        payload: "",
      },
      ParentWindowOrigin(),
     
    );

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    if (!seed) {
      return;
    }

    setStatus(Status.ProtectingSeed);

    const delivery_mechanism = params.get("delivery_mechanism");
    axios
      .post(WithApiBaseUrl("/api/v1/user/seed/protect"), {
        seed,
        entry_id: localStorage.getItem("entry_id"),
      })
      .then(() => {
        navigate({
          pathname: "/protect-seed-confirm",
          search: `?delivery_mechanism=${delivery_mechanism}`,
        });
      })
      .catch((error) => {
        console.log(error);
        setStatus(Status.Error);
      })
      .finally(() => {});
  }, [params, seed, navigate]);

  return (
    <Main>
      <Grid container spacing={2}>
        {(status === Status.ProtectingSeed ||
          status === Status.WaitingForSeed) && (
          <Grid item xs={12} textAlign={"center"} my={5}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle2" textAlign={"center"} color="#ffffff">
            Please wait while we protect your seed phrase using HyperSphere
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {status === Status.WaitingForSeed && (
            <Typography variant="subtitle2" textAlign={"center"} color="#ffffff">
              Waiting for seed...
            </Typography>
          )}
          {status === Status.ProtectingSeed && (
            <Typography variant="subtitle2" textAlign={"center"} color="#ffffff">
              Protecting seed...
            </Typography>
          )}
          {status === Status.Error && (
            <Typography color="error" variant="subtitle2" textAlign={"center"}>
              Something went wrong. {ContactSupport}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Main>
  );
};
