import {
  Grid,
  Typography,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Main from "../Layout/Main";
import { ChangeEvent, FormEvent, useState } from "react";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

export const DeliveryMechanism = () => {
  const navigate = useControlledNavigate();
  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (radioValue === "email") {
      navigate("/register-email");
    } else {
      navigate({
        pathname: "/protect-seed",
        search: "?delivery_mechanism=download",
      });
    }
  }

  const [radioValue, setRadioValue] = useState("email");

  function handleRadioChange(
    _event: ChangeEvent<HTMLInputElement>,
    value: string,
  ): void {
    setRadioValue(value);
  }

  return (
    <Main title="Delivery Mechanism">
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
              How would you like for us to deliver your protected seed phrase?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-labelledby="delivery-radio-buttons-group-label"
              name="delivery-radio-buttons-group"
              defaultValue="email"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="email"
                control={<Radio sx={{
                  color: '#bd81d6',
                  '&.Mui-checked': {
                    color: '#bd81d6',
                  },
                }} />}
                label={
                  <Typography fontWeight={"bold"} variant="subtitle2" color="#ffffff">
                    Email (Recommended)
                  </Typography>
                }
              />
              <Typography variant="subtitle2" pl={3} color="#ffffff">
                The protected seed phrase will be delivered to an email address
                that you provide. Your email provider will serve as a protected
                backup location.
              </Typography>
              <FormControlLabel
                value="download"
                control={<Radio sx={{
                  color: '#bd81d6',
                  '&.Mui-checked': {
                    color: '#bd81d6',
                  },
                }}/>}
                label={
                  <Typography fontWeight={"bold"} variant="subtitle2" color="#ffffff">
                    Direct Download
                  </Typography>
                }
              />
              <Typography variant="subtitle2" pl={3} color="#ffffff">
                The protected seed phrase will be delivered to your device
                immediately for download. Take care to store in a protected
                backup location. The protected seed phrase will be delivered to
                your device immediately for download. Take care to store in a
                protected backup location.
              </Typography>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign={"center"} variant="subtitle2" color="#ffffff">
              Click Next to proceed.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" px={"25%"}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disableElevation
                style={{
                  borderRadius: 8,
                  backgroundColor: "#bd81d6",
                  fontSize: "18px",
                  color: "#ffffff"
                }}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Main>
  );
};
