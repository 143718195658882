import { Box, Stack, Typography } from "@mui/material";
import { ReactNode, forwardRef } from "react";

type MainProps = {
  title?: string;
  children: ReactNode;
};
const Main = forwardRef<HTMLDivElement, MainProps>((props: MainProps, ref) => {
  return (
    <Stack px={"15%"} maxWidth={400} ref={ref} direction="column" spacing={3}>
      <Box justifyContent="center" display="flex">
        
      </Box>
      {props.title && (
        <Typography textAlign={"center"} variant="h6"
        sx={{ color: 'white' }}
        >
          {props.title}
        </Typography>
      )}
      <Box>{props.children}</Box>
    </Stack>
  );
});

export default Main;
