import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Main from "../Layout/Main";
import { ChangeEvent, FormEvent, useState } from "react";
import axios from "axios";
import { WithApiBaseUrl } from "../Util/Environment";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

export const ConfirmEmail = () => {
  const navigate = useControlledNavigate();

  const [error, setError] = useState("");
  const [touched, setTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [emailCodeInput, setEmailCodeInput] = useState("");
  const [emailCodeInputError, setEmailCodeInputError] = useState("");

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (!emailCodeInput) {
      setEmailCodeInputError("Please enter a verification code");
      return;
    }

    if (emailCodeInputError) {
      return;
    }

    setSubmitting(true);
    axios
      .post(WithApiBaseUrl("/api/v1/user/email/verify"), {
        code: emailCodeInput,
        entry_id: localStorage.getItem("entry_id"),
      })
      .then(() => {
        navigate({
          pathname: "/protect-seed",
          search: "?delivery_mechanism=email",
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setEmailCodeInputError("Invalid verification code");
        } else {
          console.log(error);
          setError("Failed to verify email");
        }
      })
      .finally(() => {
        setSubmitting(false);
        setEmailCodeInput("");
      });
  }

  function handleEmailCodeChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setEmailCodeInput(event.target.value);
    if (!event.target.value) {
      setEmailCodeInputError("Please enter a verification code");
    } else {
      setEmailCodeInputError("");
    }
  }

  return (
    <Main>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign={"center"} color="#ffffff">
              Please enter the verification code that was delivered to your
              email address and click Submit. Upon verification of the code, we
              will begin the process of protecting your seed phrase using
              HyperSphere.
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <FormControl>
              <InputLabel htmlFor="verification" 
              sx={{
                color: '#bd81d6',
                '&.Mui-focused': {
                  color: '#bd81d6',
                },
              }}
              >Verification Code</InputLabel>
              <OutlinedInput
                id="verification"
                label="Verification Code"
                autoFocus
                disabled={submitting}
                onChange={handleEmailCodeChange}
                autoComplete="off"
                value={emailCodeInput}
                onBlur={() => {
                  setTouched(true);
                }}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bd81d6',
                  },
                  input: {
                    color: 'white',
                  },
                }}
              />
              {touched && (
                <FormHelperText error id="verification">
                  {emailCodeInputError}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Typography variant="body1" textAlign={"center"} color="#ffffff">
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={submitting}
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Main>
  );
};
