import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { MainLayout } from "./Layout/MainLayout";
import { RecoveryPrompt } from "./Pages/RecoveryPrompt";
import { RegisterEmail } from "./Pages/RegisterEmail";
import { RegisterTOTP } from "./Pages/RegisterTOTP";
import { Instructions } from "./Pages/Instructions";
import { ConfirmEmail } from "./Pages/ConfirmEmail";
import { DeliveryMechanism } from "./Pages/DeliveryMechanism";
import { RecoveryConfirm } from "./Pages/RecoveryConfirm";
import { Welcome } from "./Pages/Welcome";
import { ProtectSeed } from "./Pages/ProtectSeed";
import { ProtectSeedConfirm } from "./Pages/ProtectSeedConfirm";
import { ControlledRoute } from "./Path/ControlledRoute";
import { RedirectionPage } from "./Path/RedirectionPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Welcome />,
        },
        {
          path: "/instructions",
          element: (
            <ControlledRoute>
              <Instructions />
            </ControlledRoute>
          ),
        },
        {
          path: "/register-totp",
          element: (
            <ControlledRoute>
              <RegisterTOTP />
            </ControlledRoute>
          ),
        },
        {
          path: "/delivery-mechanism",
          element: (
            <ControlledRoute>
              <DeliveryMechanism />
            </ControlledRoute>
          ),
        },
        {
          path: "/register-email",
          element: (
            <ControlledRoute>
              <RegisterEmail />
            </ControlledRoute>
          ),
        },
        {
          path: "/confirm-email",
          element: (
            <ControlledRoute>
              <ConfirmEmail />
            </ControlledRoute>
          ),
        },
        {
          path: "/recovery",
          element: <RecoveryPrompt />,
        },
        {
          path: "/recovery-confirm",
          element: (
            <ControlledRoute>
              <RecoveryConfirm />
            </ControlledRoute>
          ),
        },
        {
          path: "/protect-seed",
          element: (
            <ControlledRoute>
              <ProtectSeed />
            </ControlledRoute>
          ),
        },
        {
          path: "/protect-seed-confirm",
          element: (
            <ControlledRoute>
              <ProtectSeedConfirm />
            </ControlledRoute>
          ),
        },
        {
          path: "*",
          element: <RedirectionPage />,
        },
      ],
    },
  ]);

  const theme = createTheme({
    palette: {
      primary: {
        light: "#91e2ff",
        main: "#00b0f0",
        dark: "#008abd",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
