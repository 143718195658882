import { Grid, Typography, Box, Button } from "@mui/material";
import Main from "../Layout/Main";
import { useControlledNavigate } from "../Path/UseRestrictedNavigate";

export const Instructions = () => {
  const navigate = useControlledNavigate();
  return (
    <Main title="">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant="subtitle2"  color="#ffffff">
            The seed protection process consists of the following two steps:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6"  color="#ffffff">1. Registration</Typography>
          <Typography variant="subtitle2"  color="#ffffff">
            You will register an anonymous account using a QR Code and a Time-
            Based One-Time Password (TOTP)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6"  color="#ffffff">2. Delivery</Typography>
          <Typography variant="subtitle2"  color="#ffffff">
            We will deliver to you the Protected Seed Phrase either via email or
            direct download
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign={"center"} variant="subtitle2"  color="#ffffff">
            Click Next to proceed.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" px={"25%"}>
            <Button
              onClick={() => {
                navigate("/register-totp");
              }}
              variant="contained"
              fullWidth
              disableElevation
              style={{
                borderRadius: 8,
                backgroundColor: "#bd81d6",
                fontSize: "18px",
                color: "#ffffff"
            }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Main>
  );
};
